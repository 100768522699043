.avatar{
  height: 25px !important;
  width: 25px !important;
}
.avatar-container{
  height: 40px !important;
  width: 40px !important;
  background-color: #c7c7c7 !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px !important;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu{
  right: 0px !important;
  padding: 0px !important;
  z-index: 99999;
}
.info-container{
  background-color: #093249;
  color:#ffff ;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
@media screen and (min-width: 1400px) {
  .wsmainwp {
    max-width: 1310px;
  }
}
@media (min-width: 992px) and (max-width: 1199.95px) {
  .wsmainwp {
    max-width: 978px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399.95px) {
  .wsmainwp {
    max-width: 1130px !important;
  }
}

.visible{
  opacity: 1;
  visibility: visible;
}
.invisible{
  opacity: 0 ;
  visibility: unset ;
}