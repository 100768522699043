.terms_banner {
  padding-top: 153px;
  padding-bottom: 70px;
}

@media screen and (max-width: 576px) {
  .terms_banner {
    padding-top: 135px !important;
    padding-bottom: 16px !important;
  }
}
