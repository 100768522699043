.create-project .Hcontainer {
  text-align: top;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.create-project .Fcontainer {
  font-family: Arial, sans-serif;

  display: inline-block;
  position: center;
}

.create-project h2 {
  color: #333;
  text-align: center;
}

.create-project label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  color: #333;
}

.form-control,
.form-select,
.create-project select,
.create-project textarea,
.create-project input[type="text"],
.create-project input[type="file"],
.create-project input[type="email"] {
  width: 100%;
  outline: 0px none;
  border: 1px solid #ccc;
  border-radius: 4px ;
  font-size: 14px !important;
  padding: 6px 12px !important;
  height: 46px !important;
}
.create-project select::placeholder,
.create-project textarea::placeholder,
.create-project input[type="text"]::placeholder,
.create-project input[type="file"]::placeholder,
.create-project input[type="email"]::placeholder {
  font-size: 14px;
  text-transform: capitalize;
}

.required_label{
  font-weight: 600;
  font-size: 18px;
  padding: 0px;
}

.error-message {
  text-transform: lowercase; 
  font-size: 12px;
  padding: 0px;
}

.error-message::first-letter {
  text-transform: capitalize;
}


.create-project input[type="submit"] {
  background-color: #28a745;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.create-project input[type="submit"]:hover {
  background-color: #218838;
}

.create-project p {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  text-align: center;
}

.entrytype {
  align-items: center;
  gap: 50px;
}
.create-project input[type="radio"] {
  --s: 1em; /* control the size */
  --c: #6db482; /* the active color */

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}
.create-project input[type="radio"]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

.create-project input[type="radio"]:disabled {
  background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
  opacity: 0.5;
  cursor: not-allowed;
}
.create-project .register-page {
  min-height: auto !important;
}
.create-project .register-page-form {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.create-project .card {
  border: none !important;
}

@media print {
  .create-project input[type="radio"] {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;
}
