body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  min-height: 100vh;
}
.page {
  min-height: 91vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pag {
  height: 100%;
}

.button {
  padding: 10px 5px;
  width: 100%;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.edit-btn {
  background-color: #4caf50 !important;
  color: white;
}

.review-btn {
  background-color: #ff9800;
  color: white;
}

button:hover {
  opacity: 0.8;
}

button:active {
  transform: scale(0.98);
}

.togle_show {
  background-color: #00ff80 !important;
  color: white;
}
.togle_hide {
  background-color: #fb0c08 !important;
  color: white;
}
