.main-banner-in {
  position: relative;
  padding-top: 180px;
  padding-bottom: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom_h {
  height: 18vh;
}

.main-banner-in::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(244, 244, 244, 0.7);
  top: 0;
  left: 0;
}
.h1-title {
  text-align: center;
}
.banner-in-title ul {
  text-decoration: none;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.banner-in-title ul li {
  margin-right: 10px;
}

.banner-in-title ul li i {
  color: #ffffff;
  font-size: 15px;
}

.banner-in-title ul li:last-child {
  margin-right: 0;
}

.banner-in-title ul li a {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
}

.banner-in-title ul li a:hover {
  color: #ffffff;
}

.banner-in-title .active a {
  color: #b68c5a;
}

.banner-in-title .active a:hover {
  color: #b68c5a;
}

.banner-in-title .active i {
  color: #b68c5a;
}
/*==========Common CSS End==========*/

/*==========Banner Start==========*/
.main-banner {
  position: relative;
}

.banner-bg-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-bg-img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 37, 65, 0.3);
}

.banner-overlay {
  position: absolute;
}

.banner-content {
  padding-top: 338px;
  padding-bottom: 226px;
}

.banner-content .h1-title {
  margin-top: 20px;
  text-align: center;
}

.banner-content p {
  margin-bottom: 0;
  margin-top: 14px;
  font-size: 18px;
  line-height: 28px;
  color: #cdcfd2;
}

.banner-btn-play-btn .sec-btn:hover {
  color: #162541 !important;
  transition: 0.5s;
}

.banner-btn-play-btn .sec-btn:hover.sec-btn::after {
  background-color: #ffffff;
  transition: 0.5s;
}

.banner-btn-play-btn {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.banner-play-btn {
  display: inline-block;
  align-items: center;
  margin-left: 30px;
}

.banner-play-btn a {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .main-banner-in {
    padding-top: 165px !important;
    padding-bottom: 88px !important;
  }
}

a {
  cursor: pointer;
}
.color_orange {
  color: #f45f0b;
}

.blog-post {
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(29, 26, 26, 0.12);
  transition: all 0.5s ease-in-out;
}
.blog-post:hover {
  background-color: #e9e9e9;
}
.blog-post-txt {
  margin-top: 16px;
}

.post_title {
  font-size: 0.8rem;
}
.blog-post-txt p {
  font-size: 0.7rem;
  line-height: 1.5;
}

.blog-post-img iframe {
  border-radius: 12px;
}

@media screen and (min-width: 992px) {
  .mt-lg-100 {
    margin-top: 100px;
  }
}

@media (min-width: 321px) and (max-width: 389.98px) {
  .division {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 390px) and (max-width: 576px) {
  .division {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199.95px) {
  .inner-page-hero {
    padding-top: 36px;
  }
}

@media (max-width: 767.9px) {
  .inner-page-hero {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .inner-page-hero {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .inner-page-hero {
    margin-top: 90px;
  }
}

@media (min-width: 992px) and (max-width: 1199.95px) {
  .inner-page-hero {
    margin-top: 40px;
  }
}

.card_image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .card_image {
    aspect-ratio: 4 / 3;
  }
}

@media (min-width: 769px) {
  .card_image {
    aspect-ratio: 4 / 3;
  }
}

.card_image {
  background-color: #000;
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
  background: #0b3837;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
  background: #f45d08;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
  background: #30b057;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
  background: #093249;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.custom_ul {
  list-style: none;
  margin-bottom: 0px;
  font-size: 14px;
}

.custom_text {
  color: #c44600;
  font-weight: 600;
}

.dark_color {
  color: #333333;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-style: italic;
}

.darko_color {
  color: #4a4a4a;
  font-weight: 400;
  font-style: italic;
}

/*==========Banner End==========*/
