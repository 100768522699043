.main-desc {
  font-size: 20px !important;
}
.offer-price {
  font-size: 19px !important;
  text-decoration: line-through !important;
  color: gray !important;
}
.price span {
  font-size: 24px !important;
}

.contact-info-card {
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 8px;
  text-align: start;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  height: 100%;
}

.contact-info-card svg {
  width: 60px;
  height: 60px;
  background-color: #ececec;
  border-radius: 2px;
  padding: 10px;
  stroke: #f45d08;
}
.contact-info-card h5 {
  font-size: 1.2rem;
  font-weight: 600;
}
.contact-info-card p {
  font-size: 0.9rem;
  margin: 0;
}
.section-title {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 1.8rem;
}
.content a {
  color: #8c8c8c;
}
a:hover {
  color: #f45f0b !important;
}

.pricing_cards_custom .p-table h5,
.pricing_cards_custom .p-table h6,
.pricing_cards_custom .p-table span,
.pricing_cards_custom .p-table {
  transition: all 0.5s ease-in-out;
}
.pricing_cards_custom .p-table:hover {
  background-color: #093249;
  color: #fff;
}
.pricing_cards_custom .p-table li {
  margin-bottom: 0rem;
  font-size: 15px;
}
.pricing_cards_custom .p-table li p {
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 0;
}
.pricing_cards_custom .p-table:hover span,
.pricing_cards_custom .p-table:hover h5,
.pricing_cards_custom .p-table:hover h6 {
  color: #fff;
}

.pricing_cards_custom .p-table:hover .hover--tra-black:hover {
  color: #f45d08 !important;
  background-color: transparent !important;
  border-color: #f45d08 !important;
}

ul {
  list-style-type: none; /* Removes default list bullets */
  padding-left: 0;
}

.advanced::before {
  content: "★ "; /* Adds a star before each list item */
  color: #6db482;
  height: 20px;
  width: 20px;
  font-size: 28px; /* Change color of the star */
}
.advanced .price {
  color: #f45d08;
}
.pricing_cards_custom .p-table:hover .advanced .price {
  color: #f45d08;
}
