.register-page .logo-black img {
  width: auto;
  max-width: inherit;
  max-height: 50px;
}
#signup,
#reset-password,
#login {
    padding-top: 100px;
  padding-bottom: 50px;
  
}

.profile-avatar {
  height: 40px !important;
  width: 40px !important;
}
.profile .avatar-container {
  background-color: #c7c7c7 !important;
  height: 60px !important;
  width: 65px !important;
}
.card-style1 {
  box-shadow: 0px 0px 10px 0px rgb(89 75 128 / 9%);
}
.border-0 {
  border: 0 !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

section {
  padding: 120px 0;
  overflow: hidden;
  background: #fff;
}
.mb-2-3,
.my-2-3 {
  margin-bottom: 2.3rem;
}

.section-title {
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}
.text-primary {
  color: #ceaa4d !important;
}
.text-secondary {
  color: #15395a !important;
}
.font-weight-600 {
  font-weight: 600;
}
.display-26 {
  font-size: 1.3rem;
}

.reset-page-wrapper,
.register-page-form {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.error-message {
  color: red;
  font-size: 15px !important;
}
.register-page-form .form-control,
.reset-page-wrapper .form-control {
  margin-bottom: 0px !important;
}
.profile table th {
  font-size: 18px !important;
}
.profile table tbody tr td {
  font-size: 16px !important;
  vertical-align: middle !important;
}
.profile table {
  border: rgba(0, 0, 0, 0.25) solid 1px !important;
  width: 100% !important;
  overflow: auto !important;
}
.profile .table-container {
  overflow: auto !important;
}
.profile .posts-category {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.register-page {
  min-height: calc(100vh - 70px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  font-size: 14px !important;
  font-weight: 700 !important;
}
.small-icon {
  font-size: 7px !important;
}
.conferm-btn {
  padding: 5px 30px !important;
  background-color: #f45d08 !important;
  transition: all 0.5s ease-in-out;
}
.conferm-btn:hover {
  background-color: var(--black) !important;
}
@media screen and (max-width: 1017px) {
  .profile table {
    width: 1000px !important;
  }
}
@media screen and (min-width: 992px) {
  .p-lg-7 {
    padding: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .p-md-6 {
    padding: 3.5rem;
  }
}
@media screen and (min-width: 576px) {
  .p-sm-2-3 {
    padding: 2.3rem;
  }
}
.p-1-9 {
  padding: 1.9rem;
}

.bg-secondary {
  background: #15395a !important;
}
@media screen and (min-width: 576px) {
  .pe-sm-6,
  .px-sm-6 {
    padding-right: 3.5rem;
  }
}
@media screen and (min-width: 576px) {
  .ps-sm-6,
  .px-sm-6 {
    padding-left: 3.5rem;
  }
}
.pe-1-9,
.px-1-9 {
  padding-right: 1.9rem;
}
.ps-1-9,
.px-1-9 {
  padding-left: 1.9rem;
}
.pb-1-9,
.py-1-9 {
  padding-bottom: 1.9rem;
}
.pt-1-9,
.py-1-9 {
  padding-top: 1.9rem;
}
.mb-1-9,
.my-1-9 {
  margin-bottom: 1.9rem;
}
@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
.rounded {
  border-radius: 0.25rem !important;
}
